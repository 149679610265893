import { useEffect, useRef, useState, useImperativeHandle, forwardRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Loader } from '@dstv-web-leanback/dstv-frontend-components'
import {
	useNavigation,
	IS_AD_PLAYING,
	GET_LIVE_AD_REQUEST,
	AD_SESSION,
	RELOAD_STREAM,
	configKeyEnabled,
	getConfigPayloadValue,
	getUserID,
	getAdsPlatformName,
} from '@dstv-web-leanback/dstv-frontend-services'
import { FocusContext, useFocusable, setFocus } from '@dstv-web-leanback/norigin-spatial-navigation'

import styles from './PulsePlayer.module.scss'
import PulseControls from './PulseControls'
import { PULSE_CONSTANTS } from './constants'
import { validateImage } from './helpers'

export const PulsePlayer = forwardRef(({ content, onEnded }, ref) => {
	const dispatch = useDispatch()

	const userState = useSelector((state) => state.user)
	const playerState = useSelector((state) => state.player)
	const adPlayerState = useSelector((state) => state.adPlayer)
	const configState = useSelector((state) => state.config)
	const entitlement = useSelector((state) => state.entitlement)

	const { liveAdRequest, isContentPlayerReady } = adPlayerState

	const adPlayerContainerRef = useRef(null)

	const pulsePlayerRefs = useRef({
		adPlayerContainer: null,
		adPlayer: null,
		adPlayerListener: null,
		session: null,
		sessionStarted: false,
		willPlayPreRoll: false,
		details: null,
		pausedAdObj: null,
		pauseAdTimeOut: null,
		pauseAdDisplayDelayedTime: null,
		firstPlay: true,
		contentVolume: 0,
		platform: getAdsPlatformName(),
		isLive: null,
		cleanUp: false,
		adType: null,
		exit: false,
		adSession: null,
		currentPlayTime: 0,
		sessionEnded: null,
		contentFinishing: false,
		adOffset: 0,
		canSkip: false,
		showSkip: false,
		skipActive: false,
		hasSkipped: false,
		contentStarted: null,
		adBreakFinishing: false,
		sessionTermination: false,
		recoveryTimeoutId: null,
		isPulseInitialized: false,
		shouldDestroyTimeout: false,
		pulseTimeoutDuration: 6000,
	})

	const [loading, setLoading] = useState(playerState.type !== 'live')
	const [hasPauseAd, setHasPauseAd] = useState(null)
	const [timeLeft, setTimeLeft] = useState(5)

	const { navigateBack } = useNavigation()

	// In case of an error or long waiting(hanging), set a timeout to recover player playback
	useEffect(() => {
		const { shouldDestroyTimeout, pulseTimeoutDuration, adPlayer } = pulsePlayerRefs.current

		if (shouldDestroyTimeout) {
			clearTimeout(pulsePlayerRefs.current.recoveryTimeoutId)
			return
		}

		pulsePlayerRefs.current.recoveryTimeoutId = setTimeout(() => {
			if (adPlayerContainerRef.current) adPlayerContainerRef.current.style.opacity = '0'

			try {
				adPlayer.stopSession()
				adPlayer.destroy()
			} catch (error) {
				console.warn('Error stopping/destroying the ad player:', error)
			}

			try {
				setLoading(false)
				content.play()
			} catch (e) {
				console.warn('content player is not available to call play -> ', e)
			}
		}, pulseTimeoutDuration)
	}, [pulsePlayerRefs.current.shouldDestroyTimeout])

	useEffect(() => {
		if (timeLeft === 0) return

		const timer = setInterval(() => {
			setTimeLeft((prevTime) => prevTime - 1)
		}, 1000)

		return () => clearInterval(timer)
	}, [timeLeft])

	useEffect(() => {
		if (!adPlayerState.hasUserPaused) return
		onPause()
	}, [adPlayerState.hasUserPaused])

	useEffect(() => {
		if (adPlayerState.adSession?.state === 'ready') {
			handleVodAdPlayback(adPlayerState.adSession?.adRequest)
			content.pause()
			startStopContent(false)
		}
	}, [adPlayerState.adSession?.state])

	useEffect(() => {
		const { state } = adPlayerState.playing
		if (!state) {
			handleFocusableVal(PULSE_CONSTANTS.FOCUSABLE.PLAY_PAUSE)
		}
		adPlayerContainerRef.current.style.opacity = adPlayerState.playing ? '1' : '0'
	}, [adPlayerState.playing])

	useEffect(() => {
		if (!canPlayVodAds()) {
			setLoading(false)
			return
		}

		const { VOD, LIVE } = PULSE_CONSTANTS.PLAYER_TYPE
		const { adRequest, channelTag } = playerState.details || {}

		if (playerState.error) return
		if (!isContentPlayerReady) return

		if (!OO?.Pulse || OO.Pulse === undefined || !content || content === undefined || entitlement.loading) return

		if (playerState.type === VOD && playerState.bookmark === null) return

		if (adPlayerState.adSession?.state === 'autoplay') {
			dispatch(AD_SESSION({ state: 'ready', adRequest: adPlayerState.adSession?.adRequest }))
			setLoading(true)
			return
		}

		if (playerState.type === VOD && canPlayVodAds() && playerState.error == null) {
			if (playerState.bookmark === 0) {
				content.pause()
			} else if (playerState.bookmark > 0) {
				pulsePlayerRefs.current.firstPlay = false
				setLoading(false)
			}
			handleVodAdPlayback(adRequest)
		}

		if (playerState.type === LIVE && playerState.error == null) {
			if (canPlayLinearAds()) {
				handleLiveAdPlayback(channelTag)
			} else {
				pulsePlayerRefs.current.firstPlay = false
			}

			adPlayerContainerRef.current.style.opacity = '0'
			setLoading(false)
		}
	}, [liveAdRequest, content, playerState.details, playerState.bookmark, isContentPlayerReady])

	useImperativeHandle(ref, () => ({
		error,
	}))

	const {
		focusKey,
		ref: focusRef,
		focusSelf,
	} = useFocusable({
		trackChildren: true,
		isFocusBoundary: true,
		onBackPress: () => {
			removeAdSession()
			exitPlayerSession('back')
		},
	})

	const handleAdPlayerFocus = () => {
		focusSelf()
	}

	const handleFocusableVal = (val) => {
		setFocus(val)
	}

	const adPlayingState = (val) => {
		dispatch(IS_AD_PLAYING({ state: val, date: Date.now() }))
	}

	const canPlayVodAds = () => {
		if (
			configKeyEnabled(configState, PULSE_CONSTANTS.AD_TYPES.PRE_ROLLS) ||
			configKeyEnabled(configState, PULSE_CONSTANTS.AD_TYPES.MID_ROLLS) ||
			configKeyEnabled(configState, PULSE_CONSTANTS.AD_TYPES.POST_ROLLS) ||
			configKeyEnabled(configState, PULSE_CONSTANTS.AD_TYPES.PAUSE_AD)
		) {
			return true
		} else {
			return false
		}
	}

	const canPlayLinearAds = () => {
		if (configKeyEnabled(configState, PULSE_CONSTANTS.AD_TYPES.PRE_ROLL_LINEAR)) {
			return true
		} else {
			return false
		}
	}

	const handleVodAdPlayback = (adRequest) => {
		pulsePlayerRefs.current.isLive = false

		if (isContentPlayerReady && adRequest?.tt?.length > 0) {
			const adRequestConfig = {
				hasBookmark: playerState.bookmark > 1 || null,
				daiPreRoll: configKeyEnabled(configState, PULSE_CONSTANTS.AD_TYPES.PRE_ROLLS),
				daiMidRoll: configKeyEnabled(configState, PULSE_CONSTANTS.AD_TYPES.MID_ROLLS),
				daiPostRoll: configKeyEnabled(configState, PULSE_CONSTANTS.AD_TYPES.POST_ROLLS),
				daiPauseAds: configKeyEnabled(configState, PULSE_CONSTANTS.AD_TYPES.PAUSE_AD),
				contentType: playerState.type,
			}
			transformAdRequestObj(adRequest, adRequestConfig)
		}
	}

	const handleLiveAdPlayback = (channelTag) => {
		pulsePlayerRefs.current.isLive = true
		if (channelTag) {
			if (liveAdRequest && isContentPlayerReady) {
				const adRequestConfig = {
					hasBookmark: false,
					daiPreRoll: configKeyEnabled(configState, PULSE_CONSTANTS.AD_TYPES.PRE_ROLL_LINEAR),
					contentType: playerState.type,
				}

				transformAdRequestObj(liveAdRequest, adRequestConfig)
			} else {
				dispatch(GET_LIVE_AD_REQUEST({ channelTag, num_of_events: 1 }))
			}
		}
	}

	const setupPulseListeners = () => [
		{
			event: OO.Pulse.AdPlayer.Events.AD_BREAK_EMPTY,
			handler: () => {
				console.warn('No playable ads')
				pulsePlayerRefs.current.firstPlay = false
				handleContentPlayerFocus()
			},
		},
		{
			event: OO.Pulse.AdPlayer.Events.SESSION_STARTED,
			handler: (event, data) => {
				pulsePlayerRefs.current.willPlayPreRoll = data.isPlayingPrerolls
				pulsePlayerRefs.current.shouldDestroyTimeout = true
				if (!data.isPlayingPrerolls) {
					pulsePlayerRefs.current.firstPlay = false
					handleContentPlayerFocus()
				}

				focusSelf()

				dispatch(AD_SESSION({ state: 'start', adRequest: null }))
			},
		},
		{
			event: OO.Pulse.AdPlayer.Events.LINEAR_AD_STARTING,
			handler: (event, data) => {
				if (pulsePlayerRefs.current.sessionTermination) {
					removeAdSession()
					return
				}

				focusSelf()
				setLoading(true)
				adPlayingState(true)
				handleFocusToAdPlayer()
				const currentTime = Math.round(content.currentTime)
				pulsePlayerRefs.current.canSkip = false
				pulsePlayerRefs.current.currentPlayTime = currentTime
				content.volume = 0
			},
		},
		{
			event: OO.Pulse.AdPlayer.Events.LINEAR_AD_PAUSED,
			handler: () => {
				pulsePlayerRefs.current.adPlayer.play()
			},
		},
		{
			event: OO.Pulse.AdPlayer.Events.LINEAR_AD_STARTED,
			handler: (event, data) => {
				const { ad } = data

				setLoading(false)
				handleFocusToAdPlayer()

				content.pause()
				startStopContent(false)

				const pulseRefs = pulsePlayerRefs.current

				const isSkippable = ad.isSkippable()
				const skipOffset = ad.getSkipOffset()

				pulseRefs.skipActive = false
				pulseRefs.canSkip = isSkippable
				pulseRefs.adOffset = isSkippable ? skipOffset : undefined

				if (pulseRefs.adOffset) setTimeLeft(pulseRefs.adOffset)

				if (isSkippable && skipOffset === 0) {
					pulseRefs.showSkip = true
				} else {
					pulseRefs.showSkip = false
				}
			},
		},
		{
			event: OO.Pulse.AdPlayer.Events.LINEAR_AD_PROGRESS,
			handler: (event, data) => {
				const pulseRefs = pulsePlayerRefs.current
				const remainingTime = Math.ceil(data.duration - data.position)

				focusSelf()

				if (remainingTime === 0) {
					pulseRefs.canSkip = false
					pulseRefs.adOffset = 0
				}

				if (pulseRefs.exit) {
					try {
						removeAdSession()
						handleContentPlayerFocus()
						adPlayerContainerRef.current.style.opacity = '0'
					} catch (error) {
						console.warn(error)
					} finally {
						pulseRefs.exit = false
					}
				}
			},
		},
		{
			event: OO.Pulse.AdPlayer.Events.SHOW_SKIP_BUTTON,
			handler: () => {
				pulsePlayerRefs.current.showSkip = true
				handleFocusToAdPlayer()
				adPlayingState(true)
				pulsePlayerRefs.current.canSkip = true
				pulsePlayerRefs.current.skipActive = true
			},
		},
		{
			event: OO.Pulse.AdPlayer.Events.LINEAR_AD_SKIPPED,
			handler: () => {
				pulsePlayerRefs.current.canSkip = false
				pulsePlayerRefs.current.adOffset = 0
				pulsePlayerRefs.current.showSkip = false
				adPlayingState(false)
				handleFocusableVal(PULSE_CONSTANTS.FOCUSABLE.PLAY_PAUSE)
			},
		},
		{
			event: OO.Pulse.AdPlayer.Events.LINEAR_AD_PLAYING,
			handler: () => {
				setLoading(false)
				resetPauseAds()
				handleFocusToAdPlayer()
				pulsePlayerRefs.current.hasSkipped = false
			},
		},
		{
			event: OO.Pulse.AdPlayer.Events.LINEAR_AD_FINISHED,
			handler: () => {
				setLoading(true)
				pulsePlayerRefs.current.adOffset = 0
				pulsePlayerRefs.current.showSkip = false
			},
		},
		{
			event: OO.Pulse.AdPlayer.Events.AD_BREAK_FINISHING,
			handler: () => {
				pulsePlayerRefs.current.firstPlay = false
				adPlayingState(true)
			},
		},
		{
			event: OO.Pulse.AdPlayer.Events.AD_BREAK_FINISHED,
			handler: (event, data) => {
				const adBreak = data.adBreak
				if (adBreak.getBreakPosition() !== 'postroll') {
					adBreakFinished()
				}
			},
		},
		{
			event: OO.Pulse.AdPlayer.Events.LINEAR_AD_ERROR,
			handler: linearAdError,
		},
	]

	const transformAdRequestObj = (adRequest, adRequestConfig) => {
		const insertionPointFilterArray = adRequest.tt
			.map((item) => {
				if (item === PULSE_CONSTANTS.MARKERS.PRE_ROLL && adRequestConfig?.daiPreRoll && !adRequestConfig?.hasBookmark) {
					return PULSE_CONSTANTS.AD_EVENT_TYPES.ON_BEFORE_CONTENT
				} else if (item === PULSE_CONSTANTS.MARKERS.MID_ROLL && adRequestConfig?.daiMidRoll) {
					return PULSE_CONSTANTS.AD_EVENT_TYPES.PLAYBACK_POSITION
				} else if (
					item === PULSE_CONSTANTS.MARKERS.PAUSE_AD &&
					playerState.type === PULSE_CONSTANTS.PLAYER_TYPE.VOD &&
					adRequestConfig?.daiPauseAds
				) {
					return PULSE_CONSTANTS.AD_EVENT_TYPES.PAUSE_CONTENT
				} else if (item === PULSE_CONSTANTS.MARKERS.POST_ROLL && adRequestConfig?.daiPostRoll) {
					return PULSE_CONSTANTS.AD_EVENT_TYPES.ON_CONTENT_END
				}
				return undefined
			})
			.filter((item) => item)

		pulsePlayerRefs.current.pauseAdDisplayDelayedTime =
			getConfigPayloadValue(configState, PULSE_CONSTANTS.AD_TYPES.PAUSE_AD, 'delay') ??
			PULSE_CONSTANTS.DEFAULT_PAUSE_AD_TIMEOUT

		const packageTag = `PA:${userState.userPackage}`
		const availabilityTag = configKeyEnabled(configState, PULSE_CONSTANTS.FEATURES.SSAI_ENABLED)
			? `AO:${configState.data?.ssai_enabled?.payload?.status || ''}`
			: ''

		const pulseTestingTags = []
		pulseTestingTags.push(packageTag)
		pulseTestingTags.push(availabilityTag)

		pulsePlayerRefs.current.details = {
			host: adRequest.pulse_host,
			contentMetadata: {
				accountCustomParameters: {
					...adRequest.acp,
				},
				category: adRequest.s,
				id: playerState.details.id,
				tags: [...adRequest.t, ...pulseTestingTags],
			},
			requestSettings: {
				enableGdpr: false,
				height: window.innerHeight,
				width: window.innerWidth,
				linearPlaybackPositions: adRequest.bp,
				insertionPointFilter: insertionPointFilterArray,
				seekMode: OO.Pulse.SeekMode.IGNORE,
				maxBitRate: PULSE_CONSTANTS.MAX_BITRATE,
				startAdTimeout: PULSE_CONSTANTS.START_AD_TIMEOUT,
				thirdPartyTimeout: PULSE_CONSTANTS.THIRD_PARTY_TIMEOUT,
				totalPassbackTimeout: PULSE_CONSTANTS.TOTAL_PASS_BACK_TIMEOUT,
			},
		}

		initializeAdPlayer()
	}

	const initializeAdPlayer = () => {
		const userID = getUserID()
		OO.Pulse.setPulseHost(pulsePlayerRefs.current.details.host, null, userID, true, true)

		pulsePlayerRefs.current.adPlayerContainer = {
			startContentPlayback() {
				startStopContent(true)
				content && content.play()
			},
			pauseContentPlayback() {
				content.pause()
				startStopContent(false)
			},
			illegalOperationOccurred(message) {
				console.warn('Illegal operation: ', message)
				illegalError()
			},
			sessionEnded() {
				console.warn('session ended')
				pulsePlayerRefs.current.exit = true
				pulsePlayerRefs.current.firstPlay = false
				setLoading(false)
				onEnded()
			},
			showPauseAd(pauseAd) {
				pulsePlayerRefs.current.pausedAdObj = pauseAd
				validateImage(pauseAd.getResourceURL(), validatePauseImageResult)
			},
			openClickThrough(url) {
				//do not delete
			},
		}

		pulsePlayerRefs.current.session = OO.Pulse.createSession(
			pulsePlayerRefs.current.details.contentMetadata,
			pulsePlayerRefs.current.details.requestSettings
		)

		pulsePlayerRefs.current.adPlayer = OO.Pulse.createAdPlayer({
			adContainerElement: adPlayerContainerRef.current,
		})

		pulsePlayerRefs.current.adPlayer.startSession(
			pulsePlayerRefs.current.session,
			pulsePlayerRefs.current.adPlayerContainer
		)

		pulsePlayerRefs.current.adPlayer.setVolume(content.volume)
		pulsePlayerRefs.current.contentVolume = content.volume

		const eventListeners = setupPulseListeners()

		const addAdPlayerEventListeners = (adPlayer, eventListeners) => {
			eventListeners.forEach(({ event, handler }) => {
				adPlayer.addEventListener(event, handler)
			})
		}

		addAdPlayerEventListeners(pulsePlayerRefs.current.adPlayer, eventListeners)

		setUpContentPlayerListeners()

		if (configKeyEnabled(configState, PULSE_CONSTANTS.FEATURES.DAI_DEBUG_ENABLED)) {
			OO.Pulse.debug = true
		}

		content && content.play()
	}

	const setUpContentPlayerListeners = () => {
		content.addEventListener('play', onPlay)
		content.addEventListener('ended', onEnd)
		content.addEventListener('timeupdate', onTimeUpdate)
	}

	const onPlay = (e) => {
		if (pulsePlayerRefs.current.firstPlay && !pulsePlayerRefs.current.sessionStarted) {
			content.pause()
			pulsePlayerRefs.current.sessionStarted = true
			pulsePlayerRefs.current.adPlayer.contentPaused()
		} else {
			if (!pulsePlayerRefs.current.contentStarted && !pulsePlayerRefs.current.firstPlay) {
				startStopContent(true)
			}

			if (pulsePlayerRefs.current.adBreakFinishing) {
				pulsePlayerRefs.current.adBreakFinishing = false

				setLoading(true)
				adPlayingState(true)

				setTimeout(() => {
					dispatch(RELOAD_STREAM({ time: pulsePlayerRefs.current.currentPlayTime, date: Date.now() }))
					adPlayingState(false)
					handleContentPlayerFocus()
				}, 300)
			}
		}

		clearTimeout(pulsePlayerRefs.current.pauseAdTimeOut)
	}

	const onPause = () => {
		if (pulsePlayerRefs.current.adPlayer && !pulsePlayerRefs.current.contentFinishing) {
			try {
				startStopContent(false)
			} catch (e) {
				console.warn('on pause error')
			}
		}
	}

	const onTimeUpdate = (event) => {
		const currentTime = Math.round(event.target.currentTime)
		const timeRemaining = Math.round(content.duration) - currentTime

		const pulseRefs = pulsePlayerRefs.current
		const isNextSecond = currentTime === pulseRefs.currentPlayTime + 1

		if (pulseRefs.contentStarted && !pulseRefs.isLive && !content.paused && !pulseRefs.firstPlay) {
			loading && isNextSecond && handleContentPlayerFocus()
			pulseRefs.adPlayer.contentPositionChanged(currentTime)
		}

		if (isNextSecond && loading) {
			console.warn('loading state disabled - volume set - setting focus to player')
			content.volume = pulseRefs.contentVolume
			handleContentPlayerFocus()
		}

		if (pulsePlayerRefs.current.showSkip) pulsePlayerRefs.current.showSkip = false
		if (pulsePlayerRefs.current.canSkip) pulsePlayerRefs.current.canSkip = false

		if (timeRemaining < 3) {
			pulseRefs.contentFinishing = true
		}
	}

	const onEnd = () => {
		pulsePlayerRefs.current.adPlayer.contentFinished()
	}

	const validatePauseImageResult = (result, img) => {
		if (result === 'show') {
			const pulseRefs = pulsePlayerRefs.current

			pulseRefs.pauseAdTimeOut = setTimeout(() => {
				setHasPauseAd(true)
				pulseRefs.pausedAdObj.adDisplayed()
				adPlayingState(true)
				handleFocusableVal(PULSE_CONSTANTS.FOCUSABLE.PULSE_PAUSE_CONTROLS)
			}, pulseRefs.pauseAdDisplayDelayedTime)
		} else {
			closeAd()
			pulsePlayerRefs.current.pausedAdObj.adFailedWithError(result)
		}
	}

	const skipAd = () => {
		if (!pulsePlayerRefs.current.skipActive) return
		pulsePlayerRefs.current.hasSkipped = true
		pulsePlayerRefs.current.adPlayer.skipButtonClicked()
	}

	const closeAd = () => {
		pulsePlayerRefs.current.pausedAdObj.adClosed()
		setHasPauseAd(false)
		handleContentPlayerFocus()
		clearTimeout(pulsePlayerRefs.current.pauseAdTimeOut)
	}

	const adBreakFinished = () => {
		pulsePlayerRefs.current.adBreakFinishing = true
		handleContentPlayerFocus()
	}

	const removeAllAdPlayerListeners = () => {
		try {
			pulsePlayerRefs.current.adPlayer.removeEventListener(OO.Pulse.AdPlayer.Events.SESSION_STARTED)
			pulsePlayerRefs.current.adPlayer.removeEventListener(OO.Pulse.AdPlayer.Events.LINEAR_AD_STARTING)
			pulsePlayerRefs.current.adPlayer.removeEventListener(OO.Pulse.AdPlayer.Events.LINEAR_AD_PAUSED)
			pulsePlayerRefs.current.adPlayer.removeEventListener(OO.Pulse.AdPlayer.Events.LINEAR_AD_STARTED)
			pulsePlayerRefs.current.adPlayer.removeEventListener(OO.Pulse.AdPlayer.Events.LINEAR_AD_PROGRESS)
			pulsePlayerRefs.current.adPlayer.removeEventListener(OO.Pulse.AdPlayer.Events.LINEAR_AD_PLAYING)
			pulsePlayerRefs.current.adPlayer.removeEventListener(OO.Pulse.AdPlayer.Events.LINEAR_AD_ERROR)
			pulsePlayerRefs.current.adPlayer.removeEventListener(OO.Pulse.AdPlayer.Events.LINEAR_AD_FINISHED)
			pulsePlayerRefs.current.adPlayer.removeEventListener(OO.Pulse.AdPlayer.Events.SHOW_SKIP_BUTTON)
			pulsePlayerRefs.current.adPlayer.removeEventListener(OO.Pulse.AdPlayer.Events.AD_BREAK_FINISHING)
			pulsePlayerRefs.current.adPlayer.removeEventListener(OO.Pulse.AdPlayer.Events.AD_BREAK_FINISHED)
			pulsePlayerRefs.current.adPlayer.removeEventListener(OO.Pulse.AdPlayer.Events.AD_BREAK_EMPTY)
		} catch (e) {
			console.warn('RemoveAllAdPlayerListeners pulse not avail')
		}
	}

	const removeContentPlayerListeners = () => {
		try {
			content.removeEventListener('play', onPlay)
			content.removeEventListener('timeupdate', onTimeUpdate)
			content.removeEventListener('ended', onEnd)
		} catch (e) {
			console.warn('RemoveContentPlayerListeners pulse not avail')
		}
	}

	const cleanUp = () => {
		pulsePlayerRefs.current.cleanUp = true
		removeAllAdPlayerListeners()
		removeContentPlayerListeners()
	}

	const startStopContent = (val) => {
		if (!pulsePlayerRefs.current.adPlayer || pulsePlayerRefs.current.firstPlay) {
			return
		}

		if (val) {
			!pulsePlayerRefs.current.sessionTermination && pulsePlayerRefs.current.adPlayer.contentStarted()
		} else {
			!pulsePlayerRefs.current.sessionTermination && pulsePlayerRefs.current.adPlayer.contentPaused()
		}

		pulsePlayerRefs.current.contentStarted = val
	}

	const handleFocusToAdPlayer = () => {
		adPlayingState(true)
		handleAdPlayerFocus()
	}

	const handleContentPlayerFocus = () => {
		setLoading(false)
		adPlayingState(false)
		handleFocusableVal(PULSE_CONSTANTS.FOCUSABLE.PLAY_PAUSE)
	}

	const resetPauseAds = () => {
		setHasPauseAd(false)
		clearTimeout(pulsePlayerRefs.current.pauseAdTimeOut)
	}

	const exitPlayerSession = (val) => {
		if (val === 'back') {
			if (hasPauseAd) {
				closeAd()
				return
			} else {
				pulsePlayerRefs.current.sessionTermination = true
				removeAdSession()
				navigateBack()
			}
		}

		handleContentPlayerFocus()

		pulsePlayerRefs.current.exit = true
	}

	const illegalError = () => {
		console.warn('Illegal Pulse Player Error has been detected!')
		handleContentPlayerFocus()
		removeAdSession()
	}

	const linearAdError = () => {
		console.warn('Pulse Ad linear error has been detected!')
		if (pulsePlayerRefs.current.sessionTermination) removeAdSession()
	}

	const error = () => {
		console.warn('useImperativeHandle error has been called. Content Player Error!!')
		pulsePlayerRefs.current.sessionTermination = true
		removeAdSession()
	}

	const removeAdSession = () => {
		console.warn('Pulse session has been stopped')
		pulsePlayerRefs.current.exit = true
		pulsePlayerRefs.current.firstPlay = false
		pulsePlayerRefs.current.adPlayerContainer = null

		try {
			adPlayerContainerRef.current.style.opacity = '0'
			pulsePlayerRefs.current.adPlayer.pause()
		} catch (e) {
			console.warn('pulsePlayerRefs.current.adPlayer')
		}
		cleanUp()
		destroy()
	}

	const destroy = () => {
		try {
			pulsePlayerRefs.current.adPlayer.stopSession()
			pulsePlayerRefs.current.adPlayer.destroy()
		} catch (e) {
			console.warn('Pulse Player is null')
		}
	}

	return (
		<FocusContext.Provider value={focusKey}>
			<div ref={focusRef} className={styles.adPlayer}>
				{hasPauseAd && (
					<div className={`${styles.pauseAdContainer}`}>
						<img
							className={styles.effect2}
							src={pulsePlayerRefs?.current?.pausedAdObj?.getResourceURL()}
							alt={'pauseAd'}
						/>
					</div>
				)}

				<div ref={adPlayerContainerRef} className={styles.adContainer} id="adContainer"></div>

				{loading && <Loader />}

				{(adPlayerState.playing || hasPauseAd) && (
					<PulseControls
						styles={styles}
						hasPauseAd={hasPauseAd}
						showSkip={pulsePlayerRefs.current.showSkip}
						canSkip={pulsePlayerRefs.current.canSkip}
						skipAd={skipAd}
						closeAd={closeAd}
						adSkipCountdown={timeLeft}
						handleFocusableVal={handleFocusableVal}
					/>
				)}
			</div>
		</FocusContext.Provider>
	)
})

export default PulsePlayer
