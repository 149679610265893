import { useEffect, useState, useMemo, useCallback } from 'react'
import { useParams, Routes, Route } from 'react-router-dom'
import {
	useFocusable,
	FocusContext,
	setFocus,
	getCurrentFocusKey,
	doesFocusableExist,
} from '@dstv-web-leanback/norigin-spatial-navigation'
import { useDispatch, useSelector } from 'react-redux'

import {
	Billboard,
	Carousel,
	DialogFull,
	getShowpageRoute,
	getVisibleCardCount,
	isLiveEventCard,
	Loader,
	MidRailBillboard,
	ModalOverlay,
	moreInfoDefault,
	moreInfoFocused,
	pixelsToVW,
	removeDefault,
	removeFocused,
	resumeDefault,
	resumeFocused,
	searchDefault,
	searchFocused,
	Toast,
	toastError,
	toastInfo,
	toastSuccess,
	navigateToContentPage,
	Grid,
	iconAddToWatchlist,
	addFocused,
} from '@dstv-web-leanback/dstv-frontend-components'
import {
	SET_BILLBOARD_INFO,
	GET_HOME_CARD_INFO,
	GET_HOME_CHANNEL_CARD_INFO,
	GET_HOME_EVENT_INFO,
	GET_CONTENT,
	getHomeBillboardData,
	getCardInfoURL,
	SET_HOME_ACTIVE_CAROUSEL,
	useNavigation,
	NAV_PUSH,
	REMOVE_ITEM_FROM_ROW,
	GET_PLAYER_DETAILS,
	getRemoveContinueWatchingEndpoint,
	SET_CONTENT_DISCOVERY_REFERRER,
	CLEAR_FOCUS_ROW,
	CLEAR_CONTENT_ROW,
	CLEAR_CONTENT_ERROR,
	replaceVirtualLinks,
	configKeyEnabled,
	getEpisodeTitle,
	PLAY,
	SHOW_HOME_TOAST,
	REMOVE_ITEM_FROM_MYSTUFF,
	getMyStuffVodEndPoint,
	TOGGLE_HOME_WATCHLIST,
	GET_HOME_WATCHLIST,
	MYSTUFF_GRID_PATH,
	MYSTUFF_PATH,
	REMOVE_ITEM_FROM_GRID_MYSTUFF,
} from '@dstv-web-leanback/dstv-frontend-services'
import { dateUtils, getCardTypeByFeatures, useDebounce } from '@dstv-web-leanback/dstv-frontend-utils'

import { focusMainNavigation } from '../../utils/helper'
import styles from './Content.module.scss'
import useHomeRowsRefresh from './helpers'
import WatchlistGrid from '../WatchlistGrid/WatchlistGrid'
import { WatchlistEmptyScreen } from '../Watchlist/WatchlistEmptyScreen'

export function Content({ isContentPage = true, currentSection, gridUrl }) {
	const dispatch = useDispatch()
	const { location, navigate, navigateBack } = useNavigation()

	const isMystuffGrid = location.pathname === MYSTUFF_GRID_PATH
	const isMystuff = location.pathname === MYSTUFF_PATH

	const configState = useSelector((state) => state.config)
	const profileState = useSelector((state) => state.profile)
	const homeState = useSelector((state) => state.home)
	const menuState = useSelector((state) => state.menu)
	const billboardState = useSelector((state) => state.billboard)

	const [homeRows, setHomeRows] = useState([])
	const [pageScroll, setPageScroll] = useState(0)
	const [isLoading, setIsLoading] = useState(true)

	const [showModal, setShowModal] = useState(false)
	const [modalOptions, setModalOptions] = useState([])

	const [image, setAdvanceMenuImage] = useState()
	const [title, setCwTitle] = useState()
	const [progress, setCwProgress] = useState()
	const [remainingTime, setRemainingTime] = useState()

	const [showToast, setShowToast] = useState(false)
	const [card_Type, setCardType] = useState()

	const [gridToast, setGridToast] = useState(false)

	const animationsEnabled = configKeyEnabled(configState, 'leanback_anim_enabled') ?? true

	const activeProfile = useMemo(
		() => profileState?.data?.items?.find((profile) => profile.active),
		[profileState?.data?.items]
	)

	// Get the 'id' query parameter
	const { id } = useParams()

	const getToastDetails = (icon, className) => {
		return {
			toastIcon: icon,
			toastClassName: className,
		}
	}

	const toastData = {
		continueWatchingToast: getToastDetails(
			!homeState.loadToastError ? toastSuccess : toastError,
			homeState.loadToastError ? styles.error : styles.success
		),
		comingUpToast: getToastDetails(
			toastInfo,
			!isContentPage
				? `${styles.home_coming_up_toast} ${card_Type === 'midrailbillboard' && styles.midrail_coming_up_toast}`
				: styles.content_coming_up_toast
		),
		myStuffToast: getToastDetails(
			!homeState.loadToastError ? toastSuccess : toastError,
			homeState.loadToastError ? styles.error : styles.success
		),
	}

	const getToastPayload = (type, item) => {
		switch (type) {
			case 'coming_up':
				return {
					id: 'comingUpToast',
					message: `${
						'Coming up  ' + dateUtils.formatDate(item?.event_start_date_time || item?.start_date_time, 'MMMM DD')
					}`,
				}
			default:
				return null
		}
	}

	useHomeRowsRefresh()

	useEffect(() => {
		if (menuState.loading === false && showModal === false) {
			if (!location.back && currentSection) {
				dispatch(GET_CONTENT({ currentSection: currentSection }))
				setIsLoading(true)
				dispatch(SET_HOME_ACTIVE_CAROUSEL({ rowIndex: 0, rowId: undefined, id: undefined, index: 0 }))
			} else if (location.focusKey && location.back) {
				if (currentSection === 'mystuff') {
					dispatch(GET_CONTENT({ currentSection: 'mystuff' }))
				}
				dispatch(
					SET_HOME_ACTIVE_CAROUSEL({
						rowIndex: location.rowIndex,
						rowId: location.rowId,
						id: location.cardId,
						index: location.cardIndex,
					})
				)
				setFocus(location.focusKey)
				if (homeState.error) {
					dispatch(CLEAR_CONTENT_ERROR())
				}
			}
		}
	}, [menuState.loading, activeProfile, location.back, isContentPage, id, currentSection, isMystuff])

	useEffect(() => {
		let homeStateRow
		if (id) {
			homeStateRow = homeState.contentrows
		} else {
			homeStateRow = homeState.rows
			dispatch(CLEAR_CONTENT_ROW({}))
		}
		setHomeRows(homeStateRow?.filter((row) => row?.items?.length))
		if (homeState.active_carousel_row_id && homeState.active_carousel_id) {
			const currentRow = homeStateRow?.find((row) => row.id === homeState?.active_carousel_row_id)
			const currentItem = currentRow?.items?.find((item) => item.id === homeState?.active_carousel_id)
			const containedFeaturesRow = currentRow?.features && currentRow?.features.includes('contained')
			const hideBillboard =
				currentRow?.features &&
				(currentRow?.features.includes('play_in_card') ||
					currentRow?.features.includes('contained') ||
					currentRow?.features.includes('big_landscape'))

			const playInBillboard = currentRow?.features && currentRow?.features.includes('play_in_billboard')
			const isSingleEvent = currentRow?.type === 'billboard' && homeStateRow.indexOf(currentRow) === 0
			const livePreview =
				(currentRow?.features &&
					currentRow?.features.includes('playback') &&
					currentRow?.features.includes('play_in_billboard')) ||
				currentRow?.type === 'channels'

			updateBillboard(
				currentItem,
				containedFeaturesRow,
				hideBillboard,
				playInBillboard,
				isSingleEvent,
				livePreview,
				currentRow?.features
			)
		}
	}, [homeState.rows, homeState.contentrows, homeState.active_carousel_id, homeState.active_carousel_row_id, id])

	useEffect(() => {
		if (homeRows?.length) {
			if (currentSection !== 'home' && currentSection !== 'mystuff') {
				setIsLoading(homeState.loading)
			} else {
				setIsLoading(false)
			}
			focusFirstCard()
		}
	}, [homeRows?.length, id])

	useEffect(() => {
		if (homeState?.focusRow) {
			let index = homeState?.rows.findIndex((o) => o.id === homeState.focusRow)
			let focusKey = ''
			if (homeState?.rows[index]?.items?.length > 0) {
				const selectedCardIndex = Math.min(homeState.active_carousel_index, homeState?.rows[index].items.length - 1)
				focusKey = `CARD_${homeState.focusRow}${selectedCardIndex}`
			} else {
				index = homeState?.rows.findIndex((o) => o.items.length)
				focusKey = `CARD_${homeState?.rows[index]?.id}0`
				dispatch(SET_HOME_ACTIVE_CAROUSEL({ rowIndex: 0, rowId: undefined, id: undefined, index: 0 }))
			}
			setIsLoading(false)

			setFocus(doesFocusableExist(focusKey) ? focusKey : focusSelf())

			dispatch(CLEAR_FOCUS_ROW({}))
		}
	}, [homeState?.focusRow, homeState?.rows, homeState?.active_carousel_index])

	const debouncedDispatch = useDebounce(
		2000,
		null,
		useCallback((action) => dispatch(action), [dispatch])
	)
	const debouncedRelatedContentDispatch = useDebounce(
		3000,
		null,
		useCallback((action) => dispatch(action), [dispatch])
	)
	const debouncedWatchlistDispatch = useDebounce(
		2000,
		null,
		useCallback((action) => dispatch(action), [dispatch])
	)

	const getCardDetails = useCallback(
		(item, rowId, index) => {
			const fullCardLink = item?.links?.find?.((link) => link.rel?.includes('full_card_info'))

			if (fullCardLink) {
				const action = GET_HOME_CARD_INFO({
					url: getCardInfoURL(item, 'full_card_info'),
					item,
					rowId,
				})

				if (index !== 0) {
					debouncedDispatch(action)
				} else {
					dispatch(action)
				}
			} else {
				item?.links?.forEach?.((link) => {
					if (link.href.includes('card_info')) {
						let actionType
						let urlType = 'card_info'

						if (link.href.includes('events')) {
							actionType = GET_HOME_EVENT_INFO
							urlType = 'event_info'
						} else if (item.type === 'channels') {
							actionType = GET_HOME_CHANNEL_CARD_INFO
						} else {
							actionType = GET_HOME_CARD_INFO
						}

						if (index !== 0) {
							debouncedDispatch(
								actionType({
									url: getCardInfoURL(item, urlType),
									item,
									rowId,
								})
							)
						} else {
							dispatch(
								actionType({
									url: getCardInfoURL(item, urlType),
									item,
									rowId,
								})
							)
						}
					} else if (link.rel.includes('related-content')) {
						let actionType = GET_HOME_CARD_INFO
						let urlType = 'related-content'
						debouncedRelatedContentDispatch(
							actionType({
								url: getCardInfoURL(item, urlType),
								item,
								rowId,
							})
						)
					} else if (link?.rel.includes('user_list') && link.method === 'GET') {
						debouncedWatchlistDispatch(
							GET_HOME_WATCHLIST({
								url: getMyStuffVodEndPoint(item),
							})
						)
					}
				})
			}
		},
		[debouncedDispatch, debouncedRelatedContentDispatch, debouncedWatchlistDispatch]
	)

	const handleNavPush = (item) => {
		dispatch(
			NAV_PUSH({
				...item,
				carouselIndex: item?.carouselIndex,
				rowTitle: item?.rowTitle,
				features: item?.features,
				key: getCurrentFocusKey(),
				location: location.pathname,
				rowIndex: item?.carouselIndex,
				rowId: item?.rowId,
				id: item?.id,
				index: item?.index,
			})
		)
	}

	const dispatchPlayerVOD = (item) => {
		if (item.type === 'event') {
			playRecording(item)
		} else {
			dispatch(
				GET_PLAYER_DETAILS({
					videoId: item.id,
					resumeVideoId: item.resume_video_id ?? item.id,
					cardId: item.id,
					rowId: item.rowId,
					type: item.type,
				})
			)
			item?.resume_state && dispatch(SET_CONTENT_DISCOVERY_REFERRER({ referrer: 'Continue Watching' }))
		}

		navigate('/play')
	}

	const playRecording = (item) => {
		const streamUrl = item.links?.find((o) => o.rel.includes('dash'))?.href
		const episodeTitle = getEpisodeTitle(item)

		dispatch(
			PLAY({
				url: streamUrl || '',
				details: {
					...item,
					episode_title: episodeTitle,
					channelTag: item.tag || item.channel_tag,
					channelNumber: item.number || item.channel_number,
				},
				type: 'recording',
			})
		)
	}

	const handleCloseToast = () => {
		setShowToast(false)
	}

	const onSelectLiveCard = (item, live = true) => {
		if (live) {
			setShowToast(false)
			if (item?.comingUp) {
				const toastPayload = getToastPayload('coming_up', item)
				if (toastPayload) {
					dispatch(SHOW_HOME_TOAST(toastPayload))
				}
				setShowToast(true)
			} else {
				let streamUrl = item?.links?.filter((link) => link?.rel?.[0] === 'stream')[0]?.href
				const episodeTitle = getEpisodeTitle(item)
				dispatch(
					PLAY({
						url: streamUrl || '',
						details: {
							...item,
							episode_title: episodeTitle,
							channelTag: item.tag || item.channel_tag,
							channelNumber: item.number || item.channel_number,
							rowTitle: item.rowTitle,
							image: item.poster_image,
						},
						type: 'live',
					})
				)

				navigate('/play', { state: { from: location.pathname.replace('/', '') } })
			}
		} else {
			dispatchPlayerVOD(item)
		}
		handleNavPush(item)
	}

	const onCarouselFocus = useCallback(
		(carouselWrapper, carouselIndex, item, rowId, index, cardType, offset) => {
			setCardType(cardType)
			if (currentSection !== 'mystuff' && cardType !== 'vod_with_progress' && item.type !== 'layout') {
				setShowToast(false)
			}
			scrollPage(carouselWrapper, cardType, offset, homeRows, rowId)
			dispatch(SET_HOME_ACTIVE_CAROUSEL({ rowIndex: carouselIndex, rowId: rowId, id: item?.id, index: index }))
		},
		[homeRows]
	)

	const shouldRenderRow = (index) => {
		return index >= homeState.active_carousel_row_index - 1 && index <= homeState.active_carousel_row_index + 1
	}

	const scrollPage = (carouselWrapper, cardType, offset, homeRows, rowId) => {
		const isCenterCard = ['containedcard', 'big16x9', 'expandablecard16x9', 'midrailbillboard'].includes(cardType)
		const targetElement = cardType === 'containedcard' ? carouselWrapper.offsetParent : carouselWrapper
		const scrollOffset = isCenterCard
			? offset - pixelsToVW((window.innerHeight - targetElement.getBoundingClientRect().height) / 2)
			: offset
		if (homeRows?.[0].id === rowId) {
			setPageScroll(0)
		} else {
			setPageScroll(scrollOffset)
		}
	}

	const onSelectPanelItem = (item) => {
		handleNavPush(item)
		if (item.longPress) {
			setShowModal(true)
			setModalOptions(getAdvanceMenuItems(item, homeRows))
			setShowToast(false)
		} else if (card_Type === 'contentpage' || (currentSection === 'mystuff' && item.type === 'layout')) {
			dispatch(GET_CONTENT({ url: replaceVirtualLinks(item.links[0].href) }))
			navigateToContentPage(item, location, navigate)
		} else {
			dispatchPlayerVOD(item)
		}
	}

	const getAdvanceMenuItems = (item, rows) => {
		const image = item?.images[0]?.href
		const isAdded = homeState?.myStuffInfo?.saved
		const myStuffBody = homeState?.myStuffInfo?.links?.find((i) => i.rel.includes('add'))?.body
		const isSectionCard = item.type === 'layout'
		setAdvanceMenuImage(image)

		if (card_Type === 'vod_with_progress') {
			const title = item?.title
			const progress = item?.resume_progress_percentage
			const remainingTime = item?.resume_remaining_mins
			setCwTitle(title)
			setCwProgress(progress)
			setRemainingTime(remainingTime)

			dispatch(
				GET_PLAYER_DETAILS({
					videoId: item.id,
					resumeVideoId: item.resume_video_id,
					cardId: item.id,
					rowId: item.rowId,
					type: item.type,
				})
			)
			return [
				{
					key: 'more_info',
					text: 'More Information',
					defaulticon: moreInfoDefault,
					focusedicon: moreInfoFocused,
					onSelect: () => {
						navigate(getShowpageRoute(item.id, item.type))
					},
					iconStyles: styles.more_info_icon,
				},
				{
					key: 'remove_continue_watching',
					text: 'Remove from Continue Watching',
					defaulticon: removeDefault,
					focusedicon: removeFocused,
					onSelect: () => {
						dispatch(
							REMOVE_ITEM_FROM_ROW({
								url: getRemoveContinueWatchingEndpoint(item),
								item: item,
								rows: rows,
								focusRow: item.rowId,
							})
						)
						setShowToast(true)
						setShowModal(false)
						setModalOptions([])
						setHomeRows([])
						setIsLoading(true)
					},
					iconStyles: styles.resume_icon,
				},
				{
					key: 'resume',
					text: 'Resume',
					defaulticon: resumeDefault,
					focusedicon: resumeFocused,
					onSelect: () => {
						dispatch(SET_CONTENT_DISCOVERY_REFERRER({ referrer: 'Continue Watching' }))
						navigate('/play')
					},
					iconStyles: styles.resume_icon,
				},
			]
		} else if (card_Type === 'recording') {
			return [
				{
					key: 'watch',
					text: 'Watch',
					defaulticon: resumeDefault,
					focusedicon: resumeFocused,
					onSelect: () => {
						playRecording(item)
						navigate('/play')
					},
					iconStyles: styles.resume_icon,
				},
				{
					key: 'myStuffToast',
					text: isSectionCard ? (isAdded ? 'Remove from my stuff' : 'Add to my stuff') : 'Remove from my stuff',
					defaulticon: isSectionCard ? (isAdded ? removeDefault : iconAddToWatchlist) : removeDefault,
					focusedicon: isSectionCard ? (isAdded ? removeFocused : addFocused) : removeFocused,
					onSelect: () => {
						if (isSectionCard && !isMystuff) {
							dispatch(
								TOGGLE_HOME_WATCHLIST({
									url: getMyStuffVodEndPoint(item),
									Toggle: isAdded ? 'REMOVE' : 'ADD',
									body: myStuffBody,
									item: item,
									rows: rows,
									focusRow: item.rowId,
								})
							)
						} else {
							if (isMystuffGrid) {
								dispatch(
									REMOVE_ITEM_FROM_GRID_MYSTUFF({
										url: getMyStuffVodEndPoint(item),
										item: item,
										rows: rows,
										focusRow: item.rowId,
										gridUrl: gridUrl,
									})
								)
								setGridToast(true)
							} else {
								dispatch(
									REMOVE_ITEM_FROM_MYSTUFF({
										url: getMyStuffVodEndPoint(item),
										item: item,
										rows: rows,
										focusRow: item.rowId,
									})
								)
							}
						}
						!isMystuffGrid && setShowToast(true)
						setShowModal(false)
						setModalOptions([])
						setHomeRows([])
						setIsLoading(true)
					},
					iconStyles: styles.resume_icon,
				},
			]
		} else {
			return [
				{
					key: 'more_info',
					text: 'More information',
					defaulticon: moreInfoDefault,
					focusedicon: moreInfoFocused,

					onSelect: () => {
						if (isSectionCard) {
							dispatch(GET_CONTENT({ url: replaceVirtualLinks(item.links[0].href) }))
							navigateToContentPage(item, location, navigate)
						} else {
							navigate(getShowpageRoute(item.id, item.type))
						}
					},
					iconStyles: styles.more_info_icon,
				},
				{
					key: 'myStuffToast',
					text: isSectionCard ? (isAdded ? 'Remove from my stuff' : 'Add to my stuff') : 'Remove from my stuff',
					defaulticon: isSectionCard ? (isAdded ? removeDefault : iconAddToWatchlist) : removeDefault,
					focusedicon: isSectionCard ? (isAdded ? removeFocused : addFocused) : removeFocused,
					onSelect: () => {
						if (isSectionCard && !isMystuff) {
							dispatch(
								TOGGLE_HOME_WATCHLIST({
									url: getMyStuffVodEndPoint(item),
									Toggle: isAdded ? 'REMOVE' : 'ADD',
									body: myStuffBody,
									item: item,
									rows: rows,
									focusRow: item.rowId,
								})
							)
						} else {
							if (isMystuffGrid) {
								dispatch(
									REMOVE_ITEM_FROM_GRID_MYSTUFF({
										url: getMyStuffVodEndPoint(item),
										item: item,
										rows: rows,
										focusRow: item.rowId,
										gridUrl: gridUrl,
									})
								)
								setGridToast(true)
							} else {
								dispatch(
									REMOVE_ITEM_FROM_MYSTUFF({
										url: getMyStuffVodEndPoint(item),
										item: item,
										rows: rows,
										focusRow: item.rowId,
									})
								)
							}
						}
						!isMystuffGrid && setShowToast(true)
						setShowModal(false)
						setModalOptions([])
						setHomeRows([])
						setIsLoading(true)
					},
					iconStyles: styles.resume_icon,
				},
			]
		}
	}

	function focusFirstCard() {
		const currentFocusKey = getCurrentFocusKey()

		if ((!currentFocusKey?.includes('CARD_') && !isMystuffGrid) || !doesFocusableExist(currentFocusKey)) {
			dispatch(SET_HOME_ACTIVE_CAROUSEL({ rowIndex: 0, rowId: undefined, id: undefined, index: 0 }))
			setFocus(`CARD_${homeRows?.[0]?.id}0`)
		}
	}

	const updateBillboard = (
		item,
		containedFeaturesRow,
		hideBillboard,
		playInBillboard,
		isSingleEvent,
		livePreview,
		currentRowFeatures
	) => {
		const billboardData = getHomeBillboardData(item)

		const details = {
			...billboardData,
			isSingleEvent: isSingleEvent,
			livePreview: livePreview,
		}

		dispatch(
			SET_BILLBOARD_INFO({
				details,
				// fromContained: containedFeaturesRow,
				hideBillboard: hideBillboard,
				// playInBillboard: playInBillboard,
				// currentRowFeatures: currentRowFeatures,
			})
		)
	}

	const { focusKey, ref, focusSelf } = useFocusable({
		onBackPress: () => {
			if (!id) focusMainNavigation()
			else navigateBack()
		},
		focusable: !!homeRows?.length,
		trackChildren: true,
	})

	if (homeState.rows.length === 0 && currentSection === 'mystuff') {
		if (homeState.loading) {
			return <Loader />
		} else {
			return <WatchlistEmptyScreen grid={false} />
		}
	}

	if (homeState.error) {
		let contentPageId = window.location.hash.split('=')[1]
		const currentRow = homeState?.rows.find((row) => row.id === homeState.active_carousel_row_id)
		const contentRow = currentRow?.items.find((row) => row.id === contentPageId)
		return (
			<DialogFull
				error={homeState.error}
				serverError={homeState.serverError}
				backAction={() => {
					!isContentPage ? navigate(0) : navigateBack()
				}}
				retryAction={() => {
					!isContentPage
						? dispatch(GET_CONTENT())
						: dispatch(GET_CONTENT({ url: replaceVirtualLinks(contentRow.links[0].href) }))
					focusMainNavigation()
				}}
			/>
		)
	}

	return (
		<>
			{isMystuffGrid ? (
				<Routes>
					<Route
						path="/grid"
						element={<WatchlistGrid showToast={gridToast} setGridToast={setGridToast} onSelect={onSelectPanelItem} />}
					/>
				</Routes>
			) : (
				<div className={`${styles.page_home} ${billboardState.hideBillboard ? styles.billboard_hidden : ''}`}>
					{isLoading && <Loader />}
					<Toast
						show={showToast}
						duration={5000}
						message={homeState.toastMessage}
						icon={toastData[homeState.toastId]?.toastIcon}
						className={toastData[homeState.toastId]?.toastClassName}
						onClose={handleCloseToast}
					/>
					{!billboardState.hideBillboard ? (
						<Billboard
							detailsVisible={true}
							billboardInfo={billboardState.info}
							playbackEnabled={menuState.hidden}
							showMetadata={isLiveEventCard(card_Type)}
						/>
					) : (
						''
					)}
					<FocusContext.Provider value={focusKey}>
						<div
							ref={ref}
							className={`${styles.home_editorials_wrapper}`}
							style={{ height: `${currentSection === 'home' ? homeState.homeOffset : homeState.offset}vw` }}
						>
							<div className={styles.home_editorials} style={{ transform: `translate3d(0,-${pageScroll}vw,0)` }}>
								{homeRows?.map((row, index) => (
									<div className={styles.home_editorial_row} key={row.id} style={{ top: `${row.rowOffset}vw` }}>
										{shouldRenderRow(index) &&
											(row.features && row.features.includes('contained') ? (
												<MidRailBillboard
													cards={row.items}
													title={row.title}
													type={row.type}
													id={row.id}
													offset={row.rowOffset}
													carouselIndex={index}
													initialIndex={
														index === homeState.active_carousel_row_index ? homeState.active_carousel_index : 0
													}
													saveLastFocusedChild={true}
													getCardDetails={getCardDetails}
													onCarouselFocus={onCarouselFocus}
													onSelect={onSelectPanelItem}
													features={row.features}
												/>
											) : (
												<Carousel
													cards={row.items}
													title={row.title}
													type={row.type}
													id={row.id}
													offset={row.rowOffset}
													carouselIndex={index}
													initialIndex={
														index === homeState.active_carousel_row_index ? homeState.active_carousel_index : 0
													}
													saveLastFocusedChild={true}
													getCardDetails={getCardDetails}
													onCarouselFocus={onCarouselFocus}
													onSelect={isLiveEventCard(card_Type) ? onSelectLiveCard : onSelectPanelItem}
													features={row.features}
													cardLimit={
														animationsEnabled
															? null
															: getVisibleCardCount(getCardTypeByFeatures(row.features, row.type))
													}
													currentSection={currentSection}
													sidebarHidden={menuState.hidden}
												/>
											))}
									</div>
								))}
							</div>
						</div>
					</FocusContext.Provider>
				</div>
			)}
			{showModal && (
				<ModalOverlay
					image={image}
					title={title}
					progress={progress}
					remainingTime={remainingTime}
					options={modalOptions}
					handleBack={() => {
						setShowModal(false)
						setModalOptions([])
						setFocus(location.focusKey)
					}}
				/>
			)}
		</>
	)
}
export default Content
